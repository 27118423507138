// @ts-ignore-line
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapbox from "!mapbox-gl";
// import mapbox from "mapbox-gl";

const BORDER_CANVAS_ID = "map-border-canvas";

export const clearBorder = (): void => {
  document.getElementById(BORDER_CANVAS_ID)?.remove();
}

export const createBorderCanvas = (map?: mapbox.Map | null): HTMLCanvasElement | null => {
  const mapCanvas = map?.getCanvas();
  if (!mapCanvas) {
    return null;
  }
  const canvas = document.createElement("canvas");
  const ratio = window.devicePixelRatio;
  canvas.width = parseFloat(mapCanvas.style.width.replace("px", "")) * ratio;
  canvas.height = parseFloat(mapCanvas.style.height.replace("px", "")) * ratio;
  canvas.style.width = mapCanvas.style.width;
  canvas.style.height = mapCanvas.style.height;
  canvas.style.zIndex = mapCanvas.style.zIndex + 1;
  canvas.style.position = "absolute";
  canvas.style.left = "0";
  canvas.style.right = "0";
  canvas.id = BORDER_CANVAS_ID;
  mapCanvas.insertAdjacentElement("afterend", canvas);
  return canvas;
}

