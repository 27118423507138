import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { STATIC_FILE_URL } from "../../constants";


export const ArticleLinks = () => {
  return (
    <Box display="flex" alignItems="center" flexDirection="column" width="90vw" textAlign="center" my={12}>
      <Typography variant="h3" textTransform="capitalize">Featured In</Typography>
      <Grid container xs={12} justifyContent="center" spacing={2} my={8}>
        <Grid item xs={6} md={3} display="flex" alignItems="center" justifyContent="center">
          <a href="https://gribnica.online/services/box-list/?category=karty-geokodirovanie#:~:text=Mappin" target="_blank" rel="noopener noreferrer">
          <img src={`${STATIC_FILE_URL}/gribnica_logo.png`} width="150px" style={{ maxWidth: "100%" }} alt="gribnica-logo" />
          </a>
        </Grid>
        <Grid item xs={6} md={3} display="flex" alignItems="center" justifyContent="center">
          <a href="https://mappinggis.com/2017/04/opciones-para-crear-mapas-personalizados/#:~:text=mappin,-Mappin" target="_blank" rel="noopener noreferrer">
            <img src={`${STATIC_FILE_URL}/mappingGIS_logo.png`} width="150px" style={{ maxWidth: "100%" }} alt="mappinggis-logo" />
          </a>
        </Grid>
        <Grid item xs={6} md={3} display="flex" alignItems="center" justifyContent="center">
          <a href="https://www.maestroalberto.it/tag/download/#:~:text=Mappin" target="_blank" rel="noopener noreferrer">
            <img src={`${STATIC_FILE_URL}/maestro_logo.png`} width="150px" style={{ maxWidth: "100%" }} alt="maestroalberto-logo" />
          </a>
        </Grid>
      </Grid>
    </Box>
  )
}