import React, { useEffect } from "react";
import { Button, Typography, Box } from "@mui/material";
import { fetchImage, loadImageFromCache, useGeneratedImage, useImageAPIError, useIsLoading } from "../../state/image";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Title from "../title";
import { STATIC_FILE_URL } from "../../constants";

const Checkout = () => {
  const dispatch = useDispatch();
  const generatedImage = useGeneratedImage();
  const error = useImageAPIError();
  const loading = useIsLoading();
  const openFeedback = () => {
    // @ts-ignore-next-line
    if (FreshworksWidget) {
      // @ts-ignore-next-line
      FreshworksWidget('open');
    }
  }
  useEffect(() => {
    dispatch(fetchImage())
    dispatch(loadImageFromCache());
    openFeedback();
  }, [dispatch])
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Title center/>
      <div style={{margin: "32px 0px"}}>
        {generatedImage ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="body1" color="primary" style={{margin: "32px 0px"}}>
                Map created! To obtain your image, just click "open image in new tab", right click the image and click "Save as".
              </Typography>
              <Button component={Link} to={{ pathname: generatedImage }} variant="outlined" target="_blank">
                Open image in new tab
              </Button>
            </Box>
          ) : (
            <>
              <Typography variant="body1" color="primary" style={{margin: "16px 0px 0px 0px"}}>
                Creating your map...
              </Typography>
              <Typography variant="body1" color="primary" style={{margin: "16px 0px"}}>
                This can take up to 2 minutes or longer depending on the size of your map.
              </Typography>
              <Button onClick={openFeedback} variant="outlined">
                Leave some feedback
              </Button>
              <Typography color="error" variant="subtitle1">
                {!loading && error ? ( error || "Unknown Error when creating image") : null }
              </Typography>
              <img alt="world loading" src={`${STATIC_FILE_URL}/world_loading_animation.gif`} style={{ width: "80vw", maxWidth: "400px" }} />
            </>
          )
        }
      </div>
      <div>
        {generatedImage && (
          <img alt="created-map" src={generatedImage} style={{ maxWidth: "80vw", maxHeight: "80vh" }}/>
        )}
      </div>
      <Typography variant="body1" color="primary" sx={{ my: "16px" }}>
        If you have any problems downloading your image, please contact {" "}
        <a style={{color: "white" }} href={"mailto:help@mappin.pro"}>help@mappin.pro</a>.
      </Typography>
      <div style={{margin: "32px 0px"}}>
        <Button component={Link} to="/" variant="contained" color="secondary">Go to home page</Button>
      </div>
    </Box>
  );
};

export { PaymentFailed } from "./paymentFailed";

export default Checkout;
