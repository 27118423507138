import React, { useEffect } from "react";
import { Button, Collapse, Grid, useTheme } from "@mui/material";
import { removeAllPins } from "../pins";
import { Step, useMap } from "../../../state/map";
import { SectionTitle } from "./SectionTitle";
import AddPin from '@mui/icons-material/AddLocationAlt';
import { StepProps } from "./types";

export const PinsStep = ({ expanded }: StepProps) => {
  const map = useMap();
  const { palette } = useTheme();
  useEffect(() => {
    window.pinsEnabled = true;
    return () => {
      window.pinsEnabled = false;
    }
  }, []);

  const onRemove = () => removeAllPins(map);

  return (
    <>
      <SectionTitle
        icon={AddPin}
        primary="Pins"
        secondary="Add pins to your map"
        step={Step.Pins}
      />
      <Collapse in={expanded} sx={{ pl: 2, py: 2, borderBottom: `${palette.primary.dark} solid 2px` }} unmountOnExit>
        <Grid item xs={12} display="flex" justifyContent="center" m={2}>
          <Button onClick={onRemove} variant="outlined">Clear all pins</Button>
        </Grid>
      </Collapse>
    </>
  );
};
