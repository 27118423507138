export enum MapStyle {
  LIGHT = "light",
  DARK = "dark",
  NATURAL = "natural",
  LOVE = "love",
  FOREST = "forest",
  WOOD = "wood",
  TERRAIN = "terrain",
  GREY = "grey",
  CARTOON = "cartoon",
  BLUE = "blue",
  SATELLITE = "satellite",
}

export enum Links {
  Home = "/",
  Create = "/create",
  Checkout = "/checkout",
  PaymentFailed = "/payment-failed"
};
