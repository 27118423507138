import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Elevation } from "./Elevation"
import { GradientText } from "./Gradient"
import { STATIC_FILE_URL } from "../../constants";

const NumberItem = ({ title, description, img }: { title: string; description: string; img: string }) => {
  const { palette } = useTheme();
  return (
    <Grid item xs={12} md={6} lg={4} display="flex" alignContent="center" flexDirection="column">
      <img src={`${STATIC_FILE_URL}/${img}`} width="100%" alt={`${title}-icon`}/>
      <GradientText variant="h4" textAlign="center">{title}</GradientText>
      <Typography variant="body1" color={palette.text.secondary} fontSize="1.15rem" p="1.5rem">
        {description}
      </Typography>
    </Grid>
  );
}

const items = [
  {
    title: "The World is Your Map",
    description: "Choose any location in the world. Simply enter your ideal location in the search bar and adjust the map area to your liking.",
    img: "/features/globe.png",
  },
  {
    title: "Create Prints of Any Size",
    description: "With 3 layouts to choose from and 4 map sizes, you can print your maps to suite any frame size. Create prints as small as your phone or as large as a 40\" TV.",
    img: "/features/print.png",
  },
  {
    title: "The Perfect Present",
    description: "Mappin maps are the perfect present for any occasion. Wall prints are perfect for anniversaries, birthdays, or Valentine's day with your partner, or as a house warming present for friends.",
    img: "/features/present.png",
  },
];

export const Features = () => {
  const { palette, breakpoints } = useTheme();
  const isSmall = useMediaQuery(breakpoints.down("md"));
  return (
    <Elevation
      p={1}
      alignItems="center"
      display="flex"
      flexDirection="column"
      my={2}
      enableMaxWidth
      zIndex={1}
      boxShadow={`${palette.secondary.light} ${isSmall ? "0px 0px" : "8px 8px"} 48px`}>
      <Grid container xs={12} justifyContent="center" spacing={1}>
        {items.map(({ title, description, img}) => (
          <NumberItem key={title} title={title} description={description} img={img}/>
        ))}
      </Grid>
    </Elevation>
  )
};