import React from "react";
import ReactDOM from "react-dom";
import posthog from "posthog-js";
import { POSTHOG_KEY } from "./constants";
import App from "./components/App";

posthog.init(POSTHOG_KEY || "", { api_host: 'https://app.posthog.com' });

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById("root")
);
