import React, { useEffect, useRef } from "react";
import { Collapse, useTheme } from "@mui/material";
import { Step, attachGeoSearch, useMap } from "../../../state/map";
import { useDispatch } from "react-redux";
import LocationIcon from '@mui/icons-material/TravelExplore';
import { SectionTitle } from "./SectionTitle";
import { StepProps } from "./types";

export const LocationStep = ({ expanded }: StepProps) => {
  const map = useMap();
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const searchRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (map && searchRef.current) {
      dispatch(attachGeoSearch(searchRef.current));
    }
  }, [map, searchRef])

  return (
    <>
      <SectionTitle
        icon={LocationIcon}
        primary="Location"
        secondary="Choose your location"
        step={Step.Location}
      />
      <Collapse in={expanded} sx={{ pl: 2, py: expanded ? 2 : 0, borderBottom: `${palette.primary.dark} solid 2px` }}>
        <div ref={searchRef} style={{width: "100%", display: "flex", justifyContent: "center", margin: "2rem 0rem" }}/>
      </Collapse>
    </>
  );
};
