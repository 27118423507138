export enum Layout {
  LANDSCAPE = "landscape",
  PORTRAIT = "portrait",
  SQUARE = "square",
}

export enum Border {
  NONE = "noop",
  FRAME = "frame",
  // CIRCLE = "circle",
}

export enum BorderStyle {
  NONE = "noop",
  SIMPLE = "simple",
  TRANSPARENT = "transparent",
  FANCY = "fancy",
  // CIRCLE = "circle",
}

export enum PaperSize {
  SINGLE = "single",
  A4 = "a4",
  A3 = "a3",
  // A2 = "a2",
  // A1 = "a1",
  // A0 = "a0"
}

