// @ts-nocheck
export const partition = <T>(elems: T[], pred: (el: T) => boolean): [T[], T[]] => {
  const truthy: T[] = [];
  const falsey: T[] = [];
  elems.forEach((el) => {
    if (pred(el)) {
      truthy.push(el);
    } else {
      falsey.push(el);
    }
  });
  return [truthy, falsey];
}

export const isEqual = (x: unknown, y: unknown): boolean => {
  if (!x || !y) {
    return x === y;
  }
  if (x === y) {
    return true;
  }
  if (typeof x !== typeof y) {
    return false;
  }
  if (Array.isArray(x) && Array.isArray(y)) {
    return x.every((v, i) => isEqual(v, y[i]));
  }
  if (typeof x === "object" && typeof y === "object") {
    const xKeys = Object.keys(x);
    const yKeys = Object.keys(y);
    if (xKeys.length === yKeys.length) {
      return xKeys.every((k) => isEqual((x as any)[k], (y as any)[k]));
    }
  }
  return false;
}

function isEmpty(x: unknown): boolean {
  if (!x) {
    return true;
  }
  if (Array.isArray(x)) {
    return x.length === 0;
  }
  if (typeof x === "object") {
    for (let i in x) {
      return false;
    }
    return true;
  }
  return false;
}

const _ = {
  isEmpty,
  isEqual,
  partition,
};

export default _;
