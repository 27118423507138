import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, ListItem, Typography, useTheme } from "@mui/material";
import { PaperSize } from "../types";
import { PaperInfo } from "../constants";
import { DropDown } from "../dropdown";
import { setSize, submitMap, useMapError, useSize } from "../../../state/map";
import { useDispatch } from "react-redux";

// TODO: select currency.
export const SubmitStep = () => {
  const theme = useTheme();
  const [disable, setDisable] = useState(false);
  const dispatch = useDispatch();
  const size = useSize();
  const error = useMapError();
  const onClick = (size: PaperSize) => {
    dispatch(setSize(size));
  }

  useEffect(() => {
    if (disable) {
      const task = setTimeout(() => setDisable(false), 10_000);
      return () => clearTimeout(task);
    }
  }, [disable, setDisable])

  const sizeOptions = Object
    .values(PaperSize)
    .map((size) => ({
      label: PaperInfo[size].name,
      value: size,
  }));

  return (
    <>
      <ListItem
        style={{
          backgroundColor: theme.palette.background.default,
          display: "flex",
          justifyContent: "start",
          flexWrap: "wrap",
        }}
      >
        {/* <ListItemIcon>
          <DownloadIcon fontSize="large" color="primary"/>
        </ListItemIcon>
        <ListItemText
          primary="Download"
          primaryTypographyProps={{ variant: "h4", color: "primary" }}
          secondary="Order your map now"
          secondaryTypographyProps={{ color: "secondary" }}
        /> */}
        <div style={{ width: "100%" }}>
          <Grid xs={12} mb={2}>
            <DropDown
              name="Size"
              initialValue={size}
              options={sizeOptions}
              onChange={onClick}
            />
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography color="primary" variant="body1" fontSize="0.75em">
              Your file will be approximately {PaperInfo[size].fileSize} in size.
            </Typography>
            <Button component={Link} to={{ pathname: "https://www.mappin.pro/map-sizes" }} variant="text" target="_blank" size="small">
              (View size comparison)
            </Button>
          </Grid>
          {error && (<Grid xs={12} mb={2}>
            <Typography variant="body1">{error}</Typography>
          </Grid>)}
          <Grid xs={12} mb={2}>
            <Button variant="contained" onClick={() => {setDisable(true); dispatch(submitMap())}} disabled={!!error || disable}>
              Create your map
            </Button>
          </Grid>
        </div>
      </ListItem>
    </>
  );
};
