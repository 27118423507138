import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, Grid, useTheme, Drawer, Toolbar, IconButton, useMediaQuery } from "@mui/material";
import { initMap, useMap } from "../../state/map";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from '@mui/icons-material/Menu';
import { Steps } from "./steps";
import MapCanvas from "./mapCanvas";
import { STATIC_FILE_URL } from "../../constants";
import { useHistory } from "react-router-dom";
import { bgGreen } from "../../theme";

export const DRAWER_WIDTH = 400;

const MapMaker = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const map = useMap();
  const mapDiv = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (mapDiv.current) {
      dispatch(initMap(mapDiv.current))
    }
  }, [dispatch, mapDiv]);

  // useEffect(() => {
  //   if (apiError) {
  //     setError(apiError);
  //   }
  // }, [apiError])

  // const generateImage = async (paperSize: PaperSize) => {
  //   if (!map) {
  //     return;
  //   }
  //   if (map.getZoom() < MIN_ZOOM_LIMIT) {
  //     setError("Zoom level too small. The map quality will degrade at this zoom level");
  //     return
  //   }
  //   const pinInfo = getPins(map);

  //   const topLeft = map.unproject([0, 0]);
  //   const bottomRight = map.unproject([
  //     LayoutInfo[layout].width,
  //     LayoutInfo[layout].height,
  //   ]);

  //   // Checking pin is within bounds of map.
  //   const validPins = pinInfo.filter((pin) =>
  //     pin.lat > bottomRight.lat
  //       && pin.lng < bottomRight.lng
  //       && pin.lat < topLeft.lat
  //       && pin.lng > topLeft.lng
  //   );
  //   if (validPins.length > NUM_PINS_LIMIT) {
  //     setError(`
  //       Too many pins.
  //       There is a limit of 20 pins.
  //       If you want this limit to change, contact help@mappin.pro"
  //     `);
  //     return;
  //   }
  //   const size = paperSize + LayoutInfo[layout].suffix;

  //   const data = {
  //     pins: validPins,
  //     pinScale: PIN_WIDTH_PX/LayoutInfo[layout].width,
  //     minLat: bottomRight.lat,
  //     minLng: topLeft.lng,
  //     maxLat: topLeft.lat,
  //     size,
  //     style: mapStyle,
  //     borderFilter: BorderInfo[border].id,
  //     borderState: borderState,
  //   };
  //   posthog.capture("generate_map_clicked", data);

  //   dispatch(triggerGenerateImage(data, history));
  // }

  return (
    <>
      {isMobile && !isOpen && (
        <IconButton sx={{ position: "absolute", top: "8px", right: "8px", zIndex: 5 }} onClick={() => setIsOpen(true)}>
          <MenuIcon fontSize="large"/>
        </IconButton>
      )}
      <Drawer
        variant="persistent"
        anchor="right"
        sx={{ width: `${DRAWER_WIDTH}px` }}
        open={isOpen || !isMobile}
        onClose={isMobile ? () => setIsOpen(false) : undefined}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "center", bgcolor: theme.palette.background.default }}>
          <Typography variant="h4" textAlign="center" onClick={() => history.push("/")} style={{ cursor: "pointer" }}>
            Mappin<img src={`${STATIC_FILE_URL}/pin-icon.png`} width="16px" style={{ marginLeft: "4px" }}/>
          </Typography>
          {isMobile && (
            <IconButton sx={{ position: "absolute", top: "8px", right: "8px" }} onClick={() => setIsOpen(false)}>
              <CloseIcon/>
            </IconButton>
          )}
        </Toolbar>
        <div style={{ width: `${DRAWER_WIDTH}px` }}>
          <Steps />
        </div>
      </Drawer>
      <div
        style={{
          padding: "2rem",
          display: "flex",
          justifyContent: "center",
          marginRight: isMobile ? "0" : `${DRAWER_WIDTH}px`,
          height: "calc(100% - 8rem)",
          overflow: "hidden"
        }}
      >
        <MapCanvas map={map} mapDiv={mapDiv}/>
      </div>
      <Grid container justifyContent="center" mb={1} color="secondary">
        <Typography color="primary" variant="body1">
          Any problems, suggestions, or concerns please email {" "}
          <a href={"mailto:help@mappin.pro"} style={{ color: "white" }}>help@mappin.pro</a>.
        </Typography>
        {/*
          Hack to preload the fonts.
          It doesn't showup since the color of the font is the same as background color.
        */}
        <div style={{ fontFamily: "Noto Sans Display" }}>.</div>
        <div style={{ fontFamily: "Noto Sans" }}>.</div>
      </Grid>
    </>
  );
};

export default MapMaker;
