import { MapStyle } from "./types";

// export const STATIC_FILE_URL = "http://localhost:8081";
export const STATIC_FILE_URL = "https://mappin-pro.netlify.app";
export const BACKEND_URL = process.env.REACT_APP_BACKEND || "";

export const GA_ID = "G-6JDZSPEPCP";
export const POSTHOG_KEY = process.env.REACT_APP_POSTHOG;

export const DEFAULT_STYLE = MapStyle.LIGHT;
export const MapIds: Record<MapStyle, string> = {
  [MapStyle.NATURAL]: "ckrtceyfu31hn1bru2erdkunk",
  [MapStyle.LIGHT]: "cksd2tl617jrz18qqmfk4iztu",
  [MapStyle.DARK]: "cks3zqjwp5txk17qwmxfwkrl9",
  [MapStyle.WOOD]: "ckxstwbp9tgmc16ul3jhysb42",
  [MapStyle.TERRAIN]: "ckwh21fr224dp14mk5e6mm841",
  [MapStyle.GREY]: "cksd2ygt066f018s3ytkdts9j",
  [MapStyle.CARTOON]: "ckxsu2krad97n15schsm8l1p6",
  [MapStyle.LOVE]: "ckub3pmlt04wr18mumixvmjoe",
  [MapStyle.FOREST]: "ckub40ya37dzh18np16vdujrx",
  [MapStyle.BLUE]: "ckwg7ytjs10ce14pf6m12erp4",
  [MapStyle.SATELLITE]: "ckwh7w4me12pj14rfgznd64zv",
}
