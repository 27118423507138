import Dexie from "dexie";

type ImageEntry = {
  id: string;
  img: string;
}

type TokenEntry = {
  id: string;
  token: string;
}

export class Database extends Dexie {
  // Declare implicit table properties.
  // (just to inform Typescript. Instanciated by Dexie in stores() method)
  images: Dexie.Table<ImageEntry, string>; // string = type of the primkey
  tokens: Dexie.Table<TokenEntry, string>;
  //...other tables goes here...

  constructor () {
      super("Database");
      this.version(3).stores({
          images: 'id, img',
          tokens: 'id, token',
          //...other tables goes here...
      });
      // The following line is needed if your typescript
      // is compiled using babel instead of tsc:
      this.images = this.table("images");
      this.tokens = this.table("tokens");
  }
}
