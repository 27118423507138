import React from "react";
import { Button, Collapse, Grid, Typography, useTheme } from "@mui/material";
import { Step, setBorder, setBorderState, useBorder } from "../../../state/map";
import { BorderStyle } from "../types";
import { BorderInfo } from "../constants";
import { clearBorder } from "../borders";
import { SectionTitle } from "./SectionTitle";
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import { useDispatch } from "react-redux";
import { StepProps } from "./types";

export const BorderStep = ({
  expanded,
}: StepProps) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const border = useBorder();

  const onChange = (border: BorderStyle) => {
    clearBorder();
    const borderInfo = BorderInfo[border];
    dispatch(setBorder(border));
    dispatch(setBorderState(borderInfo.initialState, borderInfo.drawFn));
  }

  const borderOptions = Object
    .values(BorderStyle)
    .map((border) => ({
      label: BorderInfo[border].name,
      value: border,
    }));

  const BorderComponent = BorderInfo[border].input;

  return (
    <>
      <SectionTitle
        icon={WallpaperIcon}
        primary="Border"
        secondary="Choose and personalise your border"
        step={Step.Border}
      />
      <Collapse in={expanded} sx={{ pl: 2, pb:2, borderBottom: `${palette.primary.dark} solid 2px` }}>
        <Grid item xs={12} textAlign="left">
          <Typography color="primary" variant="body1" fontSize="0.75em">
            Choose a border. <b>Hint</b>: you can make layers of the frame opaque to create some cool effects.
          </Typography>
        </Grid>
        <Grid xs={12}>
          {borderOptions.map((border) => (
            <Button
              variant="outlined"
              onClick={() => onChange(border.value)}
              style={{ margin: "8px" }}
            >
              {border.label}
            </Button>
          ))}
        </Grid>
        <Grid xs={12}>
          <BorderComponent
            key={border}
          />
        </Grid>
      </Collapse>
    </>
  );
};
