import React, { useEffect, useState } from "react";
import { Box, ClickAwayListener, IconButton, LinearProgress, MenuItem, MenuList, TextField, Typography, useTheme } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useHistory } from "react-router-dom";
import { Links } from "../../types";
import styled from "styled-components";

const MIN_SEARCH_LEN = 3;

type Place = {
  formatted_address?: string;
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    }
    viewport: {
      Ia: {
        hi: number;
        lo: number;
      };
      Wa: {
        hi: number;
        lo: number;
      };
    };
  };
};

export const Search = () => {
  const theme = useTheme();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  // const {
  //   placesService,
  //   placePredictions,
  //   getPlacePredictions,
  //   isPlacePredictionsLoading,
  // } = usePlacesService({
  //   apiKey: process.env.REACT_APP_GOOGLE_KEY,
  //   debounce: 400,
  //   options: {
  //     types: [],
  //     fields: ["geometry.location", "place_id", "formatted_address", "geometry.viewport"],
  //   },
  //   sessionToken: true,
  // });

  // useEffect(() => {
  //   if (searchTerm.length >= MIN_SEARCH_LEN) {
  //     getPlacePredictions({ input: searchTerm });
  //   }
  // },[searchTerm]);

  const locations = []; //searchTerm.length >= MIN_SEARCH_LEN ? placePredictions : [];
  const isLoading = false; // isPlacePredictionsLoading && searchTerm.length >= MIN_SEARCH_LEN;
  const close = () => setOpen(false);

  const onSubmit = (potentialPlace?: unknown) => {
    const placeItem = potentialPlace || locations[0];
    if (!placeItem) {
      history.push(Links.Create);
    } else {
      // placesService?.getDetails(placeItem, (place: Place) => {
      //   // TODO: Get primary and secondary text from data.
      //   const { viewport, location } = place.geometry;
      //   const sortedKeys = Object.keys(viewport).sort()
      //   if (sortedKeys.length === 2 && sortedKeys.every((k) => viewport[k].hi && viewport[k].lo)) {
      //     const maxLng = viewport[sortedKeys[0]].hi;
      //     const minLng = viewport[sortedKeys[0]].lo;
      //     const maxLat = viewport[sortedKeys[1]].hi;
      //     const minLat = viewport[sortedKeys[1]].lo;
      //     history.push(
      //       `${Links.Create}?maxLat=${maxLat}&maxLng=${maxLng}&minLat=${minLat}&minLng=${minLng}&place=${place.formatted_address}`
      //     );
      //   } else if (place.formatted_address) {
      //     history.push(`${Links.Create}?lat=${location.lat()}&lng=${location.lng()}&place=${place.formatted_address}`)
      //   }
      // });
      history.push(Links.Create);
    }
  }
  return (
    <ClickAwayListener onClickAway={close}>
      <div
        style={{
          position: "relative",
          marginBottom: "1rem",
          marginTop: "1rem",
          minWidth: "300px",
          maxWidth: "600px",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Choose your favourite place"
          color="secondary"
          autoFocus
          sx={{ width: "100%" }}
          InputProps={{
            autoFocus: true,
            value: searchTerm,
            onChange: (e) => {
              setSearchTerm(e.target.value);
            },
            onFocus: () => setOpen(true),
            onKeyDown: (e) => e.key === '13' && onSubmit(),
            onSubmit,
            style: {
              padding: "0",
              color: theme.palette.common.black,
              backgroundColor: theme.palette.common.white,
              marginBottom: "0.25rem",
              // borderRadius: "2rem",
              boxShadow: "0 0 5pt 2pt #AAAAAA88",
              height: "2.5rem",
            },
            inputProps: { style: { padding: "0 0 0 0.5rem", fontSize: "1rem" }, onKeyDown: (e) => e.key === '13' && onSubmit(), },
            endAdornment: (
              <Box>
                <IconButton sx={{ color: theme.palette.common.black, background: theme.palette.common.white }} onClick={onSubmit}>
                  <ArrowForwardIcon/>
                </IconButton>
              </Box>
            )
          }}
        />
        <div
          style={{
            position: "absolute",
            display: "block",
            top: "3rem",
            width: "100%",
            border: open ? "2px solid #4D8383" : undefined
          }}
        >
          { open && searchTerm.length >= MIN_SEARCH_LEN && (!!locations.length || isLoading) && (
            <>
              { isLoading && <LinearProgress color="success"/> }
              <MenuList style={{ backgroundColor: "#021816" }}>
              {locations.map((l, i) => (
                <LocationItem
                  // id={l.place_id}
                  // key={l.place_id}
                  id={`${i}`}
                  key={i}
                  onClick={() => {
                    onSubmit(l);
                    close();
                  }}
                >
                  <Typography noWrap color="inherit" variant="subtitle2" style={{ textOverflow: "ellipsis" }}>
                    {/* {l.description} */}
                    {i}
                  </Typography>
                </LocationItem>
              ))}
            </MenuList>
          </>
          )}
        </div>
      </div>
    </ClickAwayListener>
  )
}

const LocationItem = styled(MenuItem)`
  overflow-x: hidden;
  color: #FFFFFF;
  &:hover {
    background: linear-gradient(45deg, #5CE1E6, #7ED957);
    color: #000000;
  }
`;
