// @ts-ignore-line
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapbox from "!mapbox-gl";
import { MapStyle } from "../../types";
// import { CircleInput } from "./borders/circle";
import { FrameInput, frame } from "./borders/frame";
import { BorderInput } from "./borders/types";
import { Border, BorderStyle, Layout, PaperSize } from "./types";

export const StyleInfo: Record<MapStyle, { name: string, color: string }> = {
  [MapStyle.BLUE]: {name: "Blue ($2)", color: "#12345F" },
  [MapStyle.CARTOON]: {name: "Cartoon ($2)", color: "#6BBEBE" },
  [MapStyle.WOOD]: {name: "Wood ($2)", color: "#964B00" },
  [MapStyle.TERRAIN]: { name: "Terrain ($2)", color: "#68432D" },
  [MapStyle.SATELLITE]: { name: "Satellite ($3)", color: "#1F4887" },
  [MapStyle.GREY]: {name: "Grey", color: "#AAAAAA" },
  [MapStyle.DARK]: {name: "Black", color: "#000000" },
  [MapStyle.LIGHT]: {name: "White", color: "#999999" },
  [MapStyle.LOVE]: {name: "Love", color: "#D9A5BF" },
  [MapStyle.NATURAL]: { name: "Natural", color: "#86CE85" },
  [MapStyle.FOREST]: { name: "Forest", color: "#CB997E" },
}

export const LayoutInfo: Record<Layout, { name: string, heightPxPerWidthPx: number, suffix: string }> = {
  [Layout.PORTRAIT]: {
    name: "Portrait",
    suffix: "",
    heightPxPerWidthPx: Math.SQRT2,
  },
  [Layout.LANDSCAPE]: {
    name: "Landscape",
    suffix: "ls",
    heightPxPerWidthPx: Math.SQRT1_2,
  },
  [Layout.SQUARE]: {
    name: "Square",
    suffix: "sq",
    heightPxPerWidthPx: 1,
  },
};

export const BorderInfo: Record<BorderStyle, {
  name: string,
  id: Border,
  input: BorderInput,
  initialState: any,
  drawFn: (map: mapbox.Map, state: any) => void;
}> = {
  [BorderStyle.NONE]: {
    name: "None",
    id: Border.NONE,
    input: () => null,
    initialState: {},
    drawFn: () => undefined,
  },
  [BorderStyle.SIMPLE]: {
    name: "Simple",
    id: Border.FRAME,
    input: FrameInput,
    drawFn: frame,
    initialState: {
      border: Border.FRAME,
      fade: "#FFFFFF",
      frames: [
        { thickness: 0.05, color: "#FFFFFF" },
        { thickness: 0.01, color: "#222222" },
      ],
      text: {
        primary: {
          color: "#000000",
          value: "Primary",
        },
        secondary: {
          color: "#000000",
          value: "Secondary",
        },
        subtitle: {
          color: "#222222",
          value: "Subtitle",
        }
      }
    },
  },
  [BorderStyle.TRANSPARENT]: {
    name: "Transparent",
    id: Border.FRAME,
    input: FrameInput,
    drawFn: frame,
    initialState: {
      border: Border.FRAME,
      fade: "#FFFFFF",
      frames: [
        { thickness: 0.06, color: "#55555544" },
        { thickness: 0.01, color: "#222222" },
      ],
      text: {
        primary: {
          color: "#000000",
          value: "Primary",
        },
        secondary: {
          color: "#000000",
          value: "Secondary",
        },
        subtitle: {
          color: "#222222",
          value: "Subtitle",
        }
      }
    },
  },
  [BorderStyle.FANCY]: {
    name: "Fancy",
    id: Border.FRAME,
    input: FrameInput,
    drawFn: frame,
    initialState: {
      border: Border.FRAME,
      fade: "#FFFFFF",
      frames: [
        { thickness: 0.05, color: "#FFFFFF" },
        { thickness: 0.01, color: "#222222" },
        { thickness: 0.01, color: "#FFFFFF" },
        { thickness: 0.005, color: "#222222" },
      ],
      text: {
        primary: {
          color: "#000000",
          value: "Primary",
        },
        secondary: {
          color: "#000000",
          value: "Secondary",
        },
        subtitle: {
          color: "#222222",
          value: "Subtitle",
        }
      }
    },
  },
  // [Border.CIRCLE]: {
  //   name: "Circle",
  //   input: CircleInput,
  // },
};

export const PaperInfo: Record<PaperSize, { name: string, fileSize: string }> = {
  [PaperSize.SINGLE]: {
    name: "A4",
    fileSize: "1MB",
  },
  [PaperSize.A4]: {
    name: "A3", // TODO: Change to back to A2.
    fileSize: "4MB",
  },
  [PaperSize.A3]: {
    name: "A1 ($2.50)",
    fileSize: "15MB",
  },
  // [PaperSize.A2]: {
  //   name: "A0 Print"
  // },
  // [PaperSize.A1]: {
  //   name: "Professional"
  // },
  // [PaperSize.A0]: {
  //   name: "Really really big (1GB)"
  // },
};
