import React from "react";
import { ListItemButton, ListItemIcon, ListItemText, SvgIconTypeMap, useTheme } from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Step, setStep, useStep } from "../../../state/map";
import { useDispatch } from "react-redux";

type Props = {
  icon: OverridableComponent<SvgIconTypeMap>;
  primary: string;
  secondary: string
  step: Step;
}

export const SectionTitle = ({ icon: Icon, primary, secondary, step }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentStep = useStep();
  const expanded = step === currentStep;

  return (
    <>
      <ListItemButton
        onClick={() => dispatch(setStep(step))}
        style={{
          backgroundColor: expanded ? theme.palette.background.paper : theme.palette.background.default,
          display: "flex",
          justifyContent: "start",
          flexWrap: "wrap",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <ListItemIcon>
          <Icon fontSize="large" color="primary"/>
        </ListItemIcon>
        <ListItemText
          primary={primary}
          primaryTypographyProps={{ variant: "h4", color: "primary" }}
          secondaryTypographyProps={{ color: "primary", }}
        />
        {expanded ? <ExpandLess color="primary"/> : <ExpandMore color="primary"/>}
      </ListItemButton>
    </>
  );
};
