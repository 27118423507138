import { Box } from "@mui/material";
import styled from "styled-components";

export const Elevation = styled(Box)<{ enableMaxWidth?: boolean }>`
  background: ${({ theme }) =>
    `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper}) padding-box, linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.secondary.light}) border-box`
  };
  border-radius: 2rem;
  border: 4px solid transparent;
  ${({ enableMaxWidth }) => enableMaxWidth && "max-width: 1700px"};
`
