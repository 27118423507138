const WHITE = "#ffffff";
const BLACK = "#000000";

export type Border = {
  dx: number; // x offset from edge (includes thickness)
  dy: number; // y offset from edge (includes thickness)
  thickness: number;
  width: number; // including thickness
  height: number; // including thickness
  color: string;
};

export const getBorders = (width: number, height: number, frames: { thickness: number, color?: string }[]): Border[] => {
  const borders: Border[] = [];
  frames.reduce((prevBorder, { thickness, color }, i) => {
    borders.push({
      dx: prevBorder.thickness,
      dy: prevBorder.thickness,
      thickness,
      width: width - prevBorder.thickness * 2,
      height: height - prevBorder.thickness * 2,
      color: color || (i % 2 === 0 ? WHITE : BLACK),
    });
    return {
      dx: prevBorder.thickness,
      dy: prevBorder.thickness,
      thickness: prevBorder.thickness + thickness,
    };
  }, { thickness: 0 });
  return borders;
}
