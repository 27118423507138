import { createTheme, responsiveFontSizes }  from "@mui/material/styles";
import { Shadows } from "@mui/material/styles/shadows";

export const bgGreen = "#021816";
export const bgLightGreen = "#052b28";

export const white = "#ffffff";
export const offWhite = "#9AA3A2";
export const inverseText = "#000000";

export const darkBlue = "#4D8383";
export const lightBlue = "#5CE1E6";

export const darkGreen = "#42782B";
export const lightGreen = "#7ED957";


const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: darkBlue,
      light: lightBlue,
    },
      secondary: {
      main: darkGreen,
      light: lightGreen,
    },
    text: {
      primary: white,
      secondary: offWhite,
    },
    background: {
      default: bgGreen,
      paper: bgLightGreen,
    },
    divider: darkBlue,
    common: {
      black: inverseText,
      white: white,
    }
  },
  typography: {
    fontFamily: "Inter, Helvetica, sans-serif",

  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: white
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: bgGreen,
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        contained: {
          color: white,
        },
        containedPrimary: {
          backgroundColor: darkBlue,
        },
        containedSecondary: {
          backgroundColor: darkGreen,
        },
        outlined: {
          backgroundColor: bgGreen,
        },
        outlinedPrimary: {
          color: white,
        },
        outlinedSecondary: {
          color: white,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        }
      }
    }
  },
  shadows: Array(25).fill("none") as Shadows,
});

const reponsiveTheme = responsiveFontSizes(theme);

export default reponsiveTheme;
