import { Action } from "redux";
import { MapState } from "./map";
import {useSelector as useReduxSelector, TypedUseSelectorHook} from "react-redux";
import { ThunkAction as ReactThunkAction} from 'redux-thunk';
import { ImageState } from "./image";

export type RootState = {
  map: MapState;
  image: ImageState
};

export type ThunkAction<ReturnType = void> =
  ReactThunkAction<ReturnType, RootState, {}, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
