import React, {useEffect, useRef, useState} from "react";
// @ts-ignore-line
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"; // todo: exclaim
import { useLayout } from "../../state/map";
import { Layout } from "./types";
import { LayoutInfo } from "./constants";
import { useMediaQuery, useTheme } from "@mui/material";
import { DRAWER_WIDTH } from ".";

type Dimensions = { width: number; height: number };

const calculateDimensions = (container: Dimensions, layout: Layout): Dimensions => {
  const layoutInfo = LayoutInfo[layout];
  const heightPxPerWidthPx = layoutInfo.heightPxPerWidthPx;
  // Try to calculate the height using the width.
  const width = container.width;
  const height = Math.round(width * heightPxPerWidthPx);
  if (height <= container.height) {
    // If it fits use it.
    return { width, height };
  }
  // Otherwise calculate the width using the height.
  const newHeight = container.height;
  const newWidth = Math.round(newHeight / heightPxPerWidthPx);
  return { width: newWidth, height: newHeight };
}

const MapCanvas = ({ map, mapDiv }: { map: mapboxgl.Map | null; mapDiv: React.RefObject<HTMLDivElement> }) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const layout = useLayout();

  const [dimensions, setDimensions] = useState(
    calculateDimensions({ width: window.innerWidth, height: window.innerHeight }, layout)
  );

  const updateDimensions = (container: React.RefObject<HTMLDivElement>, layout: Layout) => {
    // Min-width will set the width to be greater than 1.
    const currentHeight = container.current?.offsetHeight || 0;
    const currentWidth = container.current?.offsetWidth || 1;
    const calculatedDimensions = calculateDimensions({ width: currentWidth, height: currentHeight}, layout);
    setDimensions(calculatedDimensions);
    map?.resize();
  }

  useEffect(() => {
    const dimensionSetter = () => updateDimensions(mapContainer, layout);
    window.addEventListener("resize", dimensionSetter);
    return () => window.removeEventListener("resize", dimensionSetter);
  }, [layout]);

  useEffect(() => {
    updateDimensions(mapContainer, layout);
  }, [mapContainer, layout])

  useEffect(() => {
    map?.resize();
  }, [dimensions, map])

  return (
    <div ref={mapContainer} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: isMobile ? "90vw" : `calc(90vw-${DRAWER_WIDTH})`, height: "90vh" }}>
      <div ref={mapDiv} style={{ width: dimensions.width, height: dimensions.height, /*transform: "scale(0.2)",*/ boxShadow: "0 0 16px 8px #AAAAAA", }}/>
    </div>
  );
};

export default MapCanvas;
