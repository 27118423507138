import { FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

type Option<T> = {
  label: string;
  value: T;
}
type Props<T> = {
  name: string;
  initialValue: T;
  options: Option<T>[];
  disabledOptions?: Option<T>[];
  onChange: (o: T) => void;
}

const useStyles = makeStyles({
  select: {
    "&": {
      color: "white",
    },
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
  },
  icon: {
    fill: "white",
  },
  root: {
    color: "white",
  },
});

export const DropDown = <T extends string | number>({
  name,
  initialValue,
  options,
  disabledOptions = [],
  onChange,
}: Props<T>) => {
  const styles = useStyles();
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard">
      <InputLabel id={`select-label-${name}`}>{name}</InputLabel>
      <Select
        defaultChecked
        className={styles.select}
        labelId={`select-label-${name}`}
        id={`select-id-${name}`}
        value={initialValue}
        label={name}
        inputProps={{ classes: { root: styles.root, icon: styles.icon }}}
        onChange={(e) => onChange(e.target.value as T)}
      >
        {options.map((opt) => (
          <MenuItem value={opt.value} key={opt.value}>{opt.label}</MenuItem>
        ))
        }
        {disabledOptions.map((opt) => (
          <MenuItem value={opt.value} disabled key={opt.value}>{opt.label}</MenuItem>
        ))
        }
      </Select>
    </FormControl>
  )
}