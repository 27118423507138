import React from "react";
import { Button, Collapse, useTheme } from "@mui/material";
import { Step, setBorder, setLayout } from "../../../state/map";
import _ from "../../../lib/lodash";
import { BorderStyle, Layout } from "../types";
import { LayoutInfo } from "../constants";
import { SectionTitle } from "./SectionTitle";
import LayoutIcon from '@mui/icons-material/Dashboard';
import { useDispatch } from "react-redux";
import { StepProps } from "./types";

export const LayoutStep = ({ expanded }: StepProps) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const onChange = (layout: Layout) => {
    dispatch(setLayout(layout));
    dispatch(setBorder(BorderStyle.NONE));
  }

  const layoutOptions = Object
    .values(Layout)
    .map((layout) => ({
      label: LayoutInfo[layout].name,
      value: layout,
    }));
  const [enabledLayouts, disabledLayouts] = _.partition(
    layoutOptions,
    (opt) => true,// LayoutInfo[opt.value].width < dimensions.width
  );

  return (
    <>
      <SectionTitle
        icon={LayoutIcon}
        primary="Layout"
        secondary="Choose your layout"
        step={Step.Layout}
      />
      <Collapse in={expanded} sx={{ pl: 2, py: 2, borderBottom: `${palette.primary.dark} solid 2px` }} unmountOnExit>
        {enabledLayouts.map((style) => (
          <Button
            variant="outlined"
            onClick={() => onChange(style.value)}
            style={{ margin: "8px" }}
          >
            {style.label}
          </Button>
        ))}
        {disabledLayouts.map((style) => (
          <Button
            disabled
            variant="outlined"
            onClick={() => onChange(style.value)}
            style={{ margin: "8px" }}
          >
            {style.label}
          </Button>
        ))}
      </Collapse>
    </>
  );
};
