import React from "react";
import { Box, useTheme, useMediaQuery, Typography, Grid, Button } from "@mui/material";
import Tilt from 'react-parallax-tilt';
import { STATIC_FILE_URL } from "../../constants";
import { useHistory } from "react-router-dom";
import { Elevation } from "./Elevation";
import { GradientSpan, GradientText } from "./Gradient";
import styled from "styled-components";
import Carousel from "react-material-ui-carousel";

const Title = ({ small }: { small?: boolean }) => {
  return (
    <Grid xs={12}>
      <Typography
        variant={small ? "h3": "h2"}
        align="center"
        mb={2}
        style={{ fontWeight: 600 }}
      >
        Your{" "}
        <GradientSpan>
          map,{" "}
        </GradientSpan>
        your{" "}
        <GradientSpan>
          style.
        </GradientSpan>
      </Typography>
      {!small && (
        <Typography variant="h6" align="center" mx={2} mb={2}>
          With over 12 styles to choose from, 3 layouts and completely customisable borders, you can make the perfect map to suite you.
        </Typography>
      )}
    </Grid>
  )
}

const FloatingBox = styled(Box)`
  max-width: 500px;
  z-index: 1;
  animation: float-image 3.5s ease-in-out infinite;
  @keyframes float-image {
    0% {
      box-shadow: ${({ theme }) => theme.palette.primary.main} 0px 12px 16px 12px;
      // box-shadow: 0 5px 30px 0px rgba(0,0,0,0.6);
      transform: translatey(0px);
	  }
	  50% {
      box-shadow: ${({ theme }) => theme.palette.secondary.main}BB 0px 25px 32px -4px;
      // box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
      transform: translatey(-40px);
	  }
	  100% {
      box-shadow: ${({ theme }) => theme.palette.primary.main} 0px 12px 16px 12px;
	  	// box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		  transform: translatey(0px);
    }
  }
`;

const NumberItem = ({ mainText, description }: { mainText: string; description: string }) => {
  return (
    <Grid item xs={6} lg={4} display="flex" justifyContent="flex-start">
      <Box width="max-content">
        <GradientText variant="h4">{mainText}</GradientText>
        <Typography variant="body1" color="primary">{description}</Typography>
      </Box>
    </Grid>
  );
}

export const Showcase = () => {
  const { breakpoints} = useTheme();
  const history = useHistory();
  const isSmall = useMediaQuery(breakpoints.down("md"));

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" width="90%" maxWidth="1500px">
      <Box display="flex" justifyContent="space-between" flexDirection={isSmall ? "column" : "row"} alignItems="center" width="100%">
        <Grid container xs={12} md={5} justifyContent="center" margin={isSmall ? "20vh" : undefined}>
          <Tilt
            tiltReverse
            style={{ display: "flex", justifyContent: "center", width: "500px", maxWidth: "90%" }}
          >
            <FloatingBox width="100%" display="flex" justifyContent="center">
              <Carousel
                duration={1000}
                interval={3500}
                stopAutoPlayOnHover
                indicators={false}
                sx={{ width: "100%", mb: "-4px" }}
              >
                <img src={`${STATIC_FILE_URL}/madrid.png`} width="100%"/>
                <img src={`${STATIC_FILE_URL}/melbourne.png`} width="100%"/>
                <img src={`${STATIC_FILE_URL}/mexico-city.png`} width="100%"/>
                <img src={`${STATIC_FILE_URL}/san-fran.png`} width="100%"/>
                <img src={`${STATIC_FILE_URL}/cape-town.png`} width="100%"/>
                <img src={`${STATIC_FILE_URL}/mumbai.png`} width="100%"/>
                <img src={`${STATIC_FILE_URL}/boulder.png`} width="100%"/>
                <img src={`${STATIC_FILE_URL}/milford-track.png`} width="100%"/>
              </Carousel>
            </FloatingBox>
          </Tilt>
          <img src={`${STATIC_FILE_URL}/platform.png`} width="500px" style={{ maxWidth: "90%" }}/>
        </Grid>
        <Elevation justifyContent="space-between" maxWidth="90%" width="500px" p={2} sx={{ opacity: 0.94 }}>
          <Title/>
          <Grid container xs={12} justifyContent="space-between" spacing={1} rowSpacing={4} ml={0}>
            <NumberItem mainText="12" description="map styles"/>
            <NumberItem mainText="5+" description="free map styles"/>
            <NumberItem mainText=">22,000" description="maps created"/>
            <NumberItem mainText="4" description="print sizes"/>
            <NumberItem mainText="+100" description="frames"/>
            <NumberItem mainText="3" description="text options"/>
            <NumberItem mainText="Unlimited" description="text and frame colours"/>
            <NumberItem mainText="3" description="layout options"/>
            <NumberItem mainText="8+" description="zoom levels"/>
            <Grid xs={isSmall ? 6 : 12} display="flex" mt={isSmall ? "auto" : 2}>
              <Button variant="outlined" size="small" sx={{ borderRadius: "8px", py: 1.5 }} fullWidth onClick={() => history.push("/create")}>
                Create your map
              </Button>
            </Grid>
          </Grid>
        </Elevation>
      </Box>
    </Box>
  );
};
