import axios, { AxiosResponse } from "axios";
import posthog from "posthog-js";
import { useSelector, ThunkAction } from "./types";
import { MapStyle } from "../types";
import { BACKEND_URL } from "../constants";
import { Database } from "./db";
import FileSaver from "file-saver";

const IMAGE_URL_KEY = "xx-mappin-map-77";
const IMAGE_TOKEN_KEY = "xx-mappin-token-33";

const db = new Database();

export type ImageState = {
  generatedImage?: string;
  loading?: boolean;
  error?: string;
};

export type GenerateImageArgs = {
  pins: { lat: number, lng: number }[],
  minLat: number,
  minLng: number,
  maxLat: number,
  size: string,
  style: MapStyle,
};

type GenerateImageLoading = {
  type: ImageAction.GENERATE_IMAGE_LOADING;
};

type GenerateImageSuccess = {
  type: ImageAction.GENERATE_IMAGE_SUCCESS;
  image: string;
};

type GenerateImageError = {
  type: ImageAction.GENERATE_IMAGE_ERROR;
  msg?: string;
};

type ImageActions =
  | GenerateImageLoading
  | GenerateImageSuccess
  | GenerateImageError;

enum ImageAction {
  GENERATE_IMAGE_LOADING = "generate-image-loading",
  GENERATE_IMAGE_SUCCESS = "generate-image-success",
  GENERATE_IMAGE_ERROR = "generate-image-error",
};

const initalState: ImageState = {};

export const imageStore = (state: ImageState = initalState, action?: ImageActions): ImageState => {
  switch(action?.type) {
    case ImageAction.GENERATE_IMAGE_ERROR:
      return { ...state, error: action.msg || "", loading: false, generatedImage: undefined };
    case ImageAction.GENERATE_IMAGE_LOADING:
        return { ...state, error: undefined, loading: true };
    case ImageAction.GENERATE_IMAGE_SUCCESS:
        return { ...state, error: undefined, loading: false, generatedImage: action.image };
  }
  return state;
}

export const fetchMap = async (
  token: string,
  processUrl: (url: string) => void,
  stopRetry: (error?: string) => void,
): Promise<void> => {
  try {
    const res = await axios.get<{ url: string, status: string }>(`${BACKEND_URL}/map/${token}`);
    if (res.status >= 200 && res.status <= 300 && res.data?.url) {
      processUrl(res.data.url);
    } else if (res.status >= 200 && res.status <= 300 && res.data?.status === "failure") {
      stopRetry();
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      stopRetry(e.response?.data?.msg);
    }
    return undefined;
  }
};

export const fetchImage = (maybeToken?: string): ThunkAction => async (dispatch) => {
  const token = maybeToken || (await db.tokens.get(IMAGE_TOKEN_KEY))?.token;
  if (!token) {
    return dispatch({ type: ImageAction.GENERATE_IMAGE_ERROR, msg: "Token not found. If you purchased a map, please contact support." });
  }

  dispatch({ type: ImageAction.GENERATE_IMAGE_LOADING });
  const url: string | undefined = await new Promise((resolve, reject) => {
    let poller: NodeJS.Timeout;
    const succeed = (url: string) => {
      clearInterval(poller);
      posthog.capture("image_generated_success", { token });
      dispatch({ type: ImageAction.GENERATE_IMAGE_SUCCESS, image: url });
      resolve(url);
    };
    const fail = (e: string = "Unknown error when creating image") => {
      clearInterval(poller);
      dispatch({ type: ImageAction.GENERATE_IMAGE_ERROR, msg: e });
      posthog.capture("image_generated_error", { token });
      resolve(undefined);
    };
    let i = 0;
    poller = setInterval(
      () => {
        fetchMap(token, succeed, fail);
        i += 1;
        if (i === 30) {
          clearInterval(poller);
          resolve(undefined);
        }
      },
      7 * 1000,
    );
  });
  if (!url) {
    return dispatch({ type: ImageAction.GENERATE_IMAGE_ERROR, msg: "Something went wrong getting the image URL. Please contact support." });
  }
  db.images.put({ id: IMAGE_URL_KEY, img: url });
}

export const triggerGenerateImage = (args: GenerateImageArgs): ThunkAction =>
  async (dispatch) => {
    dispatch({
      type: ImageAction.GENERATE_IMAGE_LOADING,
    });
    try {
      const res = await axios.post<{ token: string, total: number, sessionUrl: string }>(
        `${BACKEND_URL}/pricing`,
        { ...args },
      );

      const { token, sessionUrl } = res.data;

      posthog.capture(
        "map_checkout_success",
        { ...args, paid: sessionUrl.indexOf("mappin.pro") !== -1 }
      );

      if (res.status >= 200 && res.status < 300 && token) {
        await db.tokens.put({ id: IMAGE_TOKEN_KEY, token });
        if ((await db.tokens.get(IMAGE_TOKEN_KEY))?.token === token) {
          await db.images.delete(IMAGE_URL_KEY);
          window.location.assign(sessionUrl);
          return
        }
      }
    } catch (e) {
      posthog.capture("map_checkout_fail", args);
      const res = axios.isAxiosError(e) ? e.response as AxiosResponse<{ error: true, msg?: string }> : null;
      console.error("Received error", e);
      dispatch({
        type: ImageAction.GENERATE_IMAGE_ERROR,
        msg: res?.data?.msg || "Unknown error when creating image"
      })
      return;
    }
  };

export const downloadGeneratedImage = (): ThunkAction => async (dispatch) => {
  const generatedImage = await db.images.get(IMAGE_URL_KEY);
  if (generatedImage?.img) {
    dispatch({ type: ImageAction.GENERATE_IMAGE_SUCCESS, image: generatedImage.img });
    const img = await (await fetch(generatedImage.img)).blob();
    FileSaver.saveAs(img, "mappin_img.png");
  }
}

export const loadImageFromCache = (): ThunkAction => async (dispatch) => {
  try {
    const generatedImage = await db.images.get(IMAGE_URL_KEY);
    if (generatedImage?.img) {
      dispatch({ type: ImageAction.GENERATE_IMAGE_SUCCESS, image: generatedImage.img });
    }
  } catch (e) {
    return;
  }
}

export const useImageAPIError = (): string | undefined =>
  useSelector((state) => state.image.error);

export const useIsLoading = (): boolean =>
  useSelector((state) => !!state.image.loading);

export const useGeneratedImage = (): string | undefined =>
  useSelector((state) => state.image.generatedImage);
