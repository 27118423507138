
// @ts-ignore-line
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapbox from "!mapbox-gl";
// import mapbox from "mapbox-gl";

export type PinInfo = {
  lat: number;
  lng: number;
};

export const PIN_WIDTH_PX = 16;
const PIN_HEIGHT_PX = 16;

const MARKER_CLASS_NAME = "mappin-marker";

export const onMapClick = (
  map: mapbox.Map,
  removalListener: () => void,
) => (e: mapbox.MapMouseEvent) => {
  if (!window.pinsEnabled) {
    return;
  }
  const pinInfo: PinInfo = {
    lat: e.lngLat.lat,
    lng: e.lngLat.lng,
  };

  const el = document.createElement("div")
  el.style.backgroundImage = "url('/pin-icon.png')";
  el.style.width = `${PIN_WIDTH_PX}px`;
  el.style.height = `${PIN_HEIGHT_PX}px`;
  el.style.backgroundSize = '100%';
  el.style.cursor = "pointer";
  // TODO: Make it so that pins don't get created if border exists.
  el.style.zIndex = "3";
  el.className = MARKER_CLASS_NAME;
  el.id = JSON.stringify(pinInfo);
  el.addEventListener("click", (e) => {
    if (!window.pinsEnabled) {
      return;
    }
    e.stopPropagation();
    removalListener();
    el.remove();
  });

  new mapbox.Marker(el)
    .setLngLat(e.lngLat)
    .setOffset(new mapbox.Point(PIN_WIDTH_PX/2, -PIN_HEIGHT_PX/2))
    .addTo(map);
};

export const removeAllPins = (map: mapbox.Map | null): void => {
  if (!map) {
    return;
  }
  const container = map.getCanvasContainer();
  Array
    .from(container.getElementsByTagName("div"))
    .filter((el: any) => el.className.includes(MARKER_CLASS_NAME))
    .forEach((el: any) => el.remove());
}

export const getPins = (map: mapbox.Map): PinInfo[] => {
  const container = map.getCanvasContainer();
  const markers = Array
    .from(container.getElementsByTagName("div"))
    .filter((el: any) => el.className.includes(MARKER_CLASS_NAME));
  const pinInfo: PinInfo[] = markers.map((marker: any) => JSON.parse(marker.id));
  return pinInfo
}