import React from "react"
import { LayoutStep } from "./layout"
import { LocationStep } from "./location"
import { PinsStep } from "./pins"
import { StyleStep } from "./style"
import { BorderStep } from "./border";
import { SubmitStep } from "./submit"
import { Box, List, useTheme } from "@mui/material"
import { useStep } from "../../../state/map"


export const Steps = () => {
  const theme = useTheme();
  const step = useStep();
  return (
    <Box sx={{ width: "100%"}}>
      <List
        sx={{ width: '100%', bgcolor: theme.palette.background.default, p: 0 }}
        aria-labelledby="nested-list-subheader"
      >
      { //<div style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: theme.palette.secondary.dark }}>
      }
        <LocationStep expanded={step === "location"} />
        <PinsStep expanded={step === "pins"} />
        <StyleStep expanded={step === "style"} />
        <LayoutStep expanded={step === "layout"} />
        <BorderStep expanded={step === "border"} />
        <SubmitStep />
      </List>
    </Box>
  );
}
