import React from "react";
import { Button, Collapse, useTheme } from "@mui/material";
import { Step, setStyle, useStyle } from "../../../state/map";
import { MapStyle } from "../../../types";
import { StyleInfo } from "../constants";
import { SectionTitle } from "./SectionTitle";
import StyleIcon from '@mui/icons-material/FormatPaint';
import { useDispatch } from "react-redux";
import { StepProps } from "./types";

export const StyleStep = ({ expanded }: StepProps) => {
  const dispatch = useDispatch();
  const mapStyle = useStyle();
  const { palette } = useTheme();
  const onChange = (style: MapStyle) => {
    dispatch(setStyle(style));
  };
  const styleOptions = Object.values(MapStyle).map((style) => ({
      label: StyleInfo[style].name,
      value: style,
      color: StyleInfo[style].color,
  }));
  return (
    <>
      <SectionTitle
        icon={StyleIcon}
        primary="Style"
        secondary="Personalise your map style"
        step={Step.Style}
      />
      <Collapse in={expanded} sx={{ pl: 2, py: 2, borderBottom: `${palette.primary.dark} solid 2px` }} unmountOnExit>
        {styleOptions.map((style) => (
          <Button
            variant="outlined"
            onClick={() => onChange(style.value)}
            style={{
              margin: "8px",
              ...(style.value === mapStyle && { backgroundColor: style.color })
            }}
            sx={{ ":hover": { backgroundColor: style.color }}}
          >
            {style.label}
          </Button>
        ))}
      </Collapse>
    </>
  );
};
