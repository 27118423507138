import React from "react";
import { Typography, Grid, Box, useMediaQuery, useTheme } from "@mui/material";
import { GradientText } from "../dashboard/Gradient";


const Title = ({ center }: { center?: boolean}) => {
  const { breakpoints } = useTheme();
  const isSmall = useMediaQuery(breakpoints.down("sm"));
  const fontSize = isSmall ? "3.5rem" : "4.5rem";
  return (
    <Grid item xs={12} style={{ marginTop: "0", }}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h1"
          fontWeight={600}
          style={{
            fontSize,
            display: "inline",
            color: "white",
            paddingBottom: "16px",
            fontWeight: 600,
          }}
        >
          The first <GradientText variant="h1" display="inline" style={{ fontSize }}>affordable</GradientText> map creator.
        </Typography>
      </Box>
      <Typography
        variant="h5"
        component="h2"
        textAlign={center ? "center" : "left"}
      >
        Mappin lets you create and design maps that are ready to print.<br/>
        It's completely personalised, easy-to-use and <GradientText variant="h5" display="inline">FREE</GradientText> for A4 prints.
      </Typography>
    </Grid>
  );
};

export default Title;
