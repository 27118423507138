import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { imageStore } from "./image";
import { mapStore } from "./map";

const reducers = combineReducers({
  map: mapStore,
  image: imageStore,
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
