import React, { useEffect } from "react";
import {  Box, useTheme, useMediaQuery } from "@mui/material";
import { STATIC_FILE_URL } from "../../constants";
import styled from "styled-components";
import Title from "../title";
import { Search } from "./Search";
import { Showcase } from "./Showcase";
import { Testamonial } from "./Testamonial";
import { Features } from "./Features";
import { ArticleLinks } from "./ArticleLinks";
import { Navbar } from "../Navbar";
import { useDispatch } from "react-redux";
import { closeMap } from "../../state/map";

const Banner = styled(Box)<{ bgImage?: string, cover?: boolean, flexStart?: boolean }>`
  display: flex;
  justify-content: ${({ flexStart }) => flexStart ? "flex-start" : "center"};
  ${({ bgImage, cover }) => bgImage && cover
    ? `
      // box-shadow: inset 0 0 0 1000px #021816bb;
      background-image: url("/${bgImage}");
      background-size: cover;
    ` : bgImage ? `
      background-image: url("/${bgImage}");
      background-size: cover;
    ` : ""}
  background-color: #021816;
  width: 100%;
  min-height: 100vh;
`;

const Dashboard = () => {
  const { breakpoints } = useTheme();
  const isLarge = useMediaQuery(breakpoints.up("lg"));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(closeMap());
  }, [dispatch]);

  return (
    <>
      <Navbar/>
      <Banner bgImage="map-collage.png" cover flexStart>
        <Box sx={{ position: "absolute", top: "calc(max(128px, 15%))", px: "5%", width: isLarge ? "50vw" : "90%", maxWidth: "80%", ml: 4 }}>
          <Title />
          <Search />
        </Box>
      </Banner>
      <Box p={2} py="10vh" justifyContent="center" display="flex" id="features">
        <Features/>
      </Box>
      <Banner bgImage="lines.svg" alignItems="center" id="showcase">
        <Showcase />
      </Banner>
      <ArticleLinks/>
      <Banner>
        <Testamonial />
      </Banner>
    </>
  );
};

export default Dashboard;
