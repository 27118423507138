import React from "react";
import {  Box, useTheme, useMediaQuery, Typography, Grid } from "@mui/material";
import { GradientText } from "./Gradient";
import styled from "styled-components";
import { STATIC_FILE_URL } from "../../constants";

const shortReviews = [
  "Quick and easy.",
  "Love your work!",
  "Excelente aplicación",
  "Excelente servicicioooo",
  "Really nice project!",
  "Thank you so much, so useful!",
  "Muy contenta con el resultado",
];

const mediumReviews = [
  "I've found my new wallpaper set. Thank you for this.",
  "Действительно очень крутой сайт для создания креативов",
  "This is a very cool project. Thanks for your hardwork.",
  "i love this page! so easy to use.",
  "Great map making resource, thanks.",
];


const longReviews = [
  "It has been really easy! I wish I had more font options, but I'm very happy with the results",
  "Fantastic tool. I'd love the ability to create maps as zoomed out as I want",
  "I loved this tool. Easy to use and very accurate. It helped me so much with my class homework.",
  "Easy to employ and generate maps. Just what I was looking for, making it simple and understandable.",
];


const Slider = styled.div<{ widthPx: number, n: number, timeSecs: number }>`
  background:  ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
  height: 100px;
  margin: auto;
  overflow:hidden;
  position: relative;
  width: 100%;

  &::before,
  &::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 100px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    background: linear-gradient(270deg,  ${({ theme }) => theme.palette.background.default}, #FFFFFF00);
  }

  &::before {
    left: 0;
    top: 0;
    background: linear-gradient(90deg,  ${({ theme }) => theme.palette.background.default}, #FFFFFF00);
  }

  & > * {
    & > * {
      height: 100px;
      width: ${({ widthPx }) => widthPx}px;
      min-width: ${({ widthPx }) => widthPx}px;
    }
    animation: scroll ${({ timeSecs }) => timeSecs}s linear infinite;
    display: flex;
    width: ${({ widthPx, n }) => n*widthPx}px;
  }
  @keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(${({ widthPx, n}) => -widthPx*n}px);
  }

`;


// Have a look at this: https://codepen.io/studiojvla/pen/qVbQqW
export const Testamonial = () => {
  const { breakpoints, palette} = useTheme();

  return (
    <Box textAlign="center" maxWidth="2200px">
      <GradientText
        variant="h2"
        style={{ fontWeight: 600 }}
        sx={{ mt: 2 }}
      >
        What our customers say
      </GradientText>
      <Box mt={4} bgcolor={palette.background.default} justifyContent="center" sx={{ overflowX: "scroll", maxWidth: "100vw" }}>
        <img src={`${STATIC_FILE_URL}/testamonial.png`} width="100%" height="100%" style={{ minWidth: "800px" }}/>
      </Box>
      {/* <Slider widthPx={200} n={shortReviews.length} timeSecs={10}>
        <div>
          {shortReviews.map((content) => <Typography variant="body1" key={`1-${content}`}>"{content}"</Typography>)}
          {shortReviews.map((content) => <Typography variant="body1" key={`2-${content}`}>"{content}"</Typography>)}
        </div>
      </Slider>
      <Slider widthPx={250} n={shortReviews.length} timeSecs={20}>
        <div>
          {mediumReviews.map((content) => <Typography variant="body1" key={`1-${content}`}>"{content}"</Typography>)}
          {mediumReviews.map((content) => <Typography variant="body1" key={`2-${content}`}>"{content}"</Typography>)}
        </div>
      </Slider>
      <Slider widthPx={300} n={longReviews.length} timeSecs={30}>
        <div>
          {longReviews.map((content) => <Typography variant="body1" key={`1-${content}`}>"{content}"</Typography>)}
          {longReviews.map((content) => <Typography variant="body1" key={`2-${content}`}>"{content}"</Typography>)}
        </div>
      </Slider> */}
    </Box>
  );
};

const MediumReviews = () => {
  return (
    <>
      <Slider widthPx={200} n={shortReviews.length} timeSecs={10}>
        <div>
          {shortReviews.map((content) => <Typography variant="body1" key={`1-${content}`}>"{content}"</Typography>)}
          {shortReviews.map((content) => <Typography variant="body1" key={`2-${content}`}>"{content}"</Typography>)}
        </div>
      </Slider>
      <Slider widthPx={250} n={mediumReviews.length} timeSecs={20}>
        <div>
          {mediumReviews.map((content) => <Typography variant="body1" key={`1-${content}`}>"{content}"</Typography>)}
          {mediumReviews.map((content) => <Typography variant="body1" key={`2-${content}`}>"{content}"</Typography>)}
        </div>
      </Slider>
      <Slider widthPx={300} n={longReviews.length} timeSecs={30}>
        <div>
          {longReviews.map((content) => <Typography variant="body1" key={`1-${content}`}>"{content}"</Typography>)}
          {longReviews.map((content) => <Typography variant="body1" key={`2-${content}`}>"{content}"</Typography>)}
        </div>
      </Slider>
    </>
  )
}
