import { Button, Typography } from "@mui/material";
import styled from "styled-components";

export const GradientText = styled(Typography)`
  color: transparent;
  background: ${({ theme }) => `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.secondary.light})`};
  background-color: ${({ theme }) => theme.palette.text.primary};
  background-clip: text;
  -webkit-background-clip: text;
`;


export const GradientSpan = styled.span`
  color: transparent;
  background: ${({ theme }) => `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.secondary.light})`};
  background-color: ${({ theme }) => theme.palette.text.primary};
  background-clip: text;
  -webkit-background-clip: text;
`;

export const GradientButton = styled(Button)`
  background: ${({ theme }) => `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.secondary.light})`};
`;