import React from "react";
import Title from "../title";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const PaymentFailed = () => {
  return (
    <Box
      textAlign="center"
      justifyContent="center"
      display="grid"
      minHeight="100vh"
    >
      <Title/>
      <Typography variant="body1" color="primary">Payment Failed: Something went wrong with your payment :(</Typography>
      <Typography variant="body1" color="primary">
        If you think this is an issue on our end, please contact us using the form on the bottom-right.
      </Typography>
      <div>
        <Button component={Link} to={{ pathname: "/" }} variant="outlined" >
          Go Back
        </Button>
      </div>
    </Box>
  );
};
