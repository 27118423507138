import React from "react";
// @ts-ignore-line
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapbox from "!mapbox-gl";
// import mapbox from "mapbox-gl";
import { createBorderCanvas } from ".";
import { Border, getBorders } from "../../../lib/border";
import { BorderGenerator, BorderInput } from "./types";
import { Button, Grid, Slider, ToggleButton, TextField } from "@mui/material";
import { ColorPicker } from "material-ui-color";
import ClearIcon from '@mui/icons-material/Clear';
import TextureIcon from '@mui/icons-material/Texture';
import { setBorderState, useBorderState, useMap } from "../../../state/map";
import { useDispatch } from "react-redux";

const WHITE = "#FFFFFF";
const BLACK = "#000000";

const initText = (ctx: CanvasRenderingContext2D, color: string = BLACK) => {
  ctx.textAlign = "center";
  ctx.textBaseline = "bottom";
  ctx.strokeStyle = color;
  ctx.fillStyle = color;
}

type Frame = {
  thickness: number;
  color: string;
}

type Text = {
  value: string;
  color: string;
}

type FrameOptions = {
  frames: Frame[],
  fade?: string,
  text: {
    primary: Text;
    secondary: Text;
    subtitle: Text;
  };
}

const sliderToFrameValue = (sliderVal: number) => sliderVal/8;
const frameToSliderValue = (frameVal: number) => frameVal * 8;

export const FrameInput: BorderInput = () => {
  const dispatch = useDispatch();
  const map = useMap();
  const borderState = useBorderState() as FrameOptions;
  const { text, frames, fade } = borderState;
  const { primary, secondary, subtitle } = text;
  const width = parseInt(map?.getCanvas().style.width.replace("px", "") || "0");
  // useEffect(() => {
  //   clearBorder();
  //   const text = { primary, secondary, subtitle };
  //   const state: FrameOptions & { border: BorderEnum.FRAME } = {
  //     frames: frames.map((f) => ({ ...f, thickness: f.thickness})),
  //     fade,
  //     text,
  //     border: BorderEnum.FRAME as const
  //   };
  //   frame(map, state);
  //   if (!_.isEqual(borderState, state)) {
  //     setBorderState(state)
  //   }
  // }, [map, dispatch, frames, fade, primary, secondary, subtitle])
  if (!width || !map) {
    return null;
  }
  return (
    <Grid maxWidth={1500} xs={12} md={10} container item justifyContent="center">
      <Grid item container xs={12} mt={1} spacing={1}>
        <ColorPicker
          // deferred
          hideTextfield
          disableAlpha
          value={primary.color}
          onChange={(c) => dispatch(setBorderState({
            ...borderState,
            text: {
              ...borderState.text,
              primary: {...borderState.text.primary, color: `#${c.hex}`}
            }
          }, frame))}
        />
        <TextField
          label="Primary text"
          variant="standard"
          defaultValue={primary.value}
          inputProps={{ style: { color: "white" }, maxLength: 13, }}
          onChange={(e) => dispatch(setBorderState({
            ...borderState,
            text: {
              ...borderState.text,
              primary: {...borderState.text.primary, value: e.target.value}
            }
          }, frame))}
        />
      </Grid>
      <Grid item container xs={12} mt={1} spacing={1}>
        <ColorPicker
          // deferred
          hideTextfield
          disableAlpha
          value={secondary.color}
          onChange={(c) => dispatch(setBorderState({
            ...borderState,
            text: {
              ...borderState.text,
              secondary: {...borderState.text.secondary, color: `#${c.hex}`}
            }
          }, frame))}
        />
        <TextField
          label="Secondary text"
          variant="standard"
          defaultValue={secondary.value}
          inputProps={{ style: { color: "white" }, maxLength: 33, }}
          onChange={(e) => dispatch(setBorderState({
            ...borderState,
            text: {
              ...borderState.text,
              secondary: {...borderState.text.secondary, value: e.target.value}
            }
          }, frame))}
        />
      </Grid>
      <Grid item container xs={12} mt={1} spacing={1}>
        <ColorPicker
          // deferred
          hideTextfield
          disableAlpha
          value={subtitle.color}
          onChange={(c) => dispatch(setBorderState({
            ...borderState,
            text: {
              ...borderState.text,
              subtitle: {...borderState.text.subtitle, color: `#${c.hex}`}
            }
          }, frame))}
        />
        <TextField
          label="Subtitle"
          variant="standard"
          defaultValue={subtitle.value}
          inputProps={{ style: { color: "white" }, maxLength: 71, }}
          onChange={(e) => dispatch(setBorderState({
            ...borderState,
            text: {
              ...borderState.text,
              subtitle: {...borderState.text.subtitle, value: e.target.value}
            }
          }, frame))}
        />
      </Grid>
      <Grid item container xs={12} mt={1} spacing={1}>
        <ToggleButton
          size="small"
          color="primary"
          sx={{ backgroundColor: (theme) => theme.palette.secondary.dark }}
          value={"1"}
          selected={!!fade}
          onChange={() => dispatch(setBorderState({...borderState, fade: fade ? "" : WHITE }, frame))}
        >
          <TextureIcon />
        </ToggleButton>
        {!!fade && (
          <ColorPicker
            // deferred
            hideTextfield
            disableAlpha
            value={fade}
            onChange={(c) => dispatch(setBorderState({...borderState, fade: `#${c.hex}` }, frame))}
          />
        )}
      </Grid>
      {frames.map((f, i) => (
        <Grid item container xs={12} key={f.thickness + i} mt={1} spacing={1}>
          <Grid item xs={8}>
            <Slider
              color="primary"
              min={0}
              max={width}
              defaultValue={frameToSliderValue(f.thickness * width)}
              onChangeCommitted={(_, v) => {
                const newFrames = [...frames];
                newFrames[i].thickness = sliderToFrameValue(v as number)/width;
                dispatch(setBorderState({...borderState, frames}, frame));
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <ColorPicker
              // deferred
              hideTextfield
              value={f.color}
              onChange={(c) => {
                const newFrames = [...frames];
                newFrames[i].color = `#${c.hex}`;
                dispatch(setBorderState({...borderState, frames}, frame));
              }}
            />
          </Grid>
          <Grid item xs={1} display="flex">
            <Button
              size="small"
              variant="text"
              onClick={() => {
                const newFrames = [...frames];
                newFrames.splice(i, 1);
                dispatch(setBorderState({...borderState, frames: newFrames}, frame));
              }}
            >
              <ClearIcon color="error"/>
            </Button>
          </Grid>
        </Grid>
      ))}
      <Grid item container xs={12} mt={1} spacing={1}>
        <Button
          variant="outlined"
          disabled={frames.length > 4}
          onClick={() => dispatch(setBorderState({
            ...borderState,
            frames: [
              ...frames,
              { thickness: 0.05, color: "#ffffff" },
            ],
          }, frame))}
        >
          Add frame layer
        </Button>
      </Grid>
    </Grid>
  );
}

export const frame: BorderGenerator = (map: mapbox.Map | null, { frames, fade, text }: FrameOptions): void => {
  const canvas = createBorderCanvas(map);
  const ctx = canvas?.getContext("2d");
  if (!ctx || !canvas) {
    return;
  }
  ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
  const { width, height } = canvas.getBoundingClientRect();
  const borders = getBorders(width, height, frames.map((f) => ({...f, thickness: f.thickness * width})));
  borders.forEach((border) => {
    ctx.lineWidth = border.thickness;
    ctx.strokeStyle = border.color;
    ctx.strokeRect(
      border.dx + border.thickness/2,
      border.dy + border.thickness/2,
      border.width - border.thickness,
      border.height - border.thickness,
    );
  });
  const lastBorder = borders[borders.length - 1] as Border | undefined;
  const gradientDx = (lastBorder?.dx || 0) + (lastBorder?.thickness || 0);
  const gradientDy = (lastBorder?.dy || 0) + (lastBorder?.thickness || 0);
  const gradientWidth = width - 2 * gradientDx;
  const gradientHeight = height/4;
  const gradientStart = height - gradientDy - gradientHeight;
  if (fade && fade.length === 7) {
    const r = parseInt(fade.slice(1, 3), 16);
    const g = parseInt(fade.slice(3, 5), 16);
    const b = parseInt(fade.slice(5, 7), 16);
    const gradient = ctx.createLinearGradient(0, gradientStart, 0, gradientStart + gradientHeight);
    gradient.addColorStop(0, `rgba(${r}, ${g}, ${b}, 0.0)`);
    gradient.addColorStop(0.5, `rgba(${r}, ${g}, ${b}, 1.0)`);
    gradient.addColorStop(1, `rgba(${r}, ${g}, ${b}, 1.0)`);
    ctx.lineWidth = 0;
    ctx.fillStyle = gradient;
    ctx.fillRect(gradientDx, gradientStart , gradientWidth, gradientHeight);
  }

  const fontSize = Math.round(200 * Math.min(width, height)/1800);
  const maxTextWidth = gradientWidth - gradientWidth/15;
  ctx.font = `600 ${fontSize}px "Noto Sans Display", "Noto Sans"`;
  initText(ctx, text.primary.color);
  ctx.fillText(text.primary.value.toLocaleUpperCase(), width/2, gradientStart + 2.2*gradientHeight/3, maxTextWidth);

  ctx.font = `100 ${fontSize/2}px "Noto Sans Display", "Noto Sans"`;
  initText(ctx, text.secondary.color);
  ctx.fillText(text.secondary.value.toLocaleUpperCase(), width/2, gradientStart + 4.4*gradientHeight/5, maxTextWidth);

  ctx.font = `100 ${fontSize/4}px "Noto Sans Display", "Noto Sans"`;
  initText(ctx, text.subtitle.color);
  ctx.fillText(text.subtitle.value, width/2, gradientStart + 9.6*gradientHeight/10, maxTextWidth);
}
